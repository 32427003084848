@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@trust-section-cls: ~'@{ra-prefix}-cms-trust-section';
@trust-section-item-cls: ~'@{trust-section-cls}-item';
@trust-section-item-icon-cls: ~'@{trust-section-item-cls}-icon';
@trust-section-footer-cls: ~'@{trust-section-item-cls}-footer';
@trust-section-text-cls: ~'@{trust-section-cls}-text';

:export {
  trustSectionCls: @trust-section-cls;
  trustSectionItemCls: @trust-section-item-cls;
  trustSectionItemIconCls: @trust-section-item-icon-cls;
  trustSectionFooterCls: @trust-section-footer-cls;
  trustSectionTextCls: @trust-section-text-cls;
}

.@{trust-section-cls}.ra-section {
  background-color: @ra-color-grayscale-60;
  color: @ra-color-white;

  .@{trust-section-text-cls} {
    margin: 25px;

    @media only screen and (min-width: @ra-screen-sm) {
      margin: 30px 50px;
    }
  }

  .@{trust-section-item-cls} {
    text-align: center;

    .@{trust-section-item-icon-cls} {
      font-size: 60px;
      line-height: 60px;
      margin-bottom: 20px;
    }

    .ra-heading {
      font-size: 14px;
    }

    .ra-paragraph {
      margin-bottom: 15px;
    }
  }

  .@{trust-section-footer-cls} {
    margin-top: 50px;
    text-align: center;

    > span {
      text-transform: uppercase;
    }
  }
}
